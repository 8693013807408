const ID_COMPREHENSIVE_TREATMENT = ["dev", "staging"].includes(
  process.env.VUE_APP_MODE
)
  ? 17808
  : 23003;

const POLLING_INTERVAL = 4000;
const MAX_POLL_ATTEMPS = 5;

const FILE_LIMIT_EXCEED = {
  title: "พื้นที่เก็บข้อมูลเต็ม",
  message:
    "การอัปโหลดไฟล์ล้มเหลวเนื่องจากพื้นที่เก็บข้อมูลเต็ม กรุณาลบไฟล์ที่ไม่จำเป็นหรือ ติดต่อฝ่ายสนับสนุนเพื่อขอความช่วยเหลือเพิ่มเติม",
  icon: "warning",
};
const FILE_STORAGE_PLAN_EXPIRED = {
  title: "การสมัครสมาชิกหมดอายุ",
  message:
    "การอัปโหลดไฟล์ล้มเหลวเนื่องจากการสมัครสมาชิกของคุณหมดอายุ กรุณาต่ออายุการสมัครสมาชิกหรือ ติดต่อฝ่ายสนับสนุนเพื่อขอความช่วยเหลือเพิ่มเติม",
  icon: "warning",
};

const ERROR_MESSAGE_USER_REQUEST_PRINT_RECEIPT =
  "Payment process terminated: User requested receipt printing";

const REDIIVIEW_BASE_URL = "https://dservice-dc.radiiview.com/linkurl";

module.exports = {
  ID_COMPREHENSIVE_TREATMENT,
  POLLING_INTERVAL,
  MAX_POLL_ATTEMPS,
  FILE_LIMIT_EXCEED,
  FILE_STORAGE_PLAN_EXPIRED,
  ERROR_MESSAGE_USER_REQUEST_PRINT_RECEIPT,
  REDIIVIEW_BASE_URL,
};
