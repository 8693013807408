import axios from "axios";
import { eventBus } from "@/main";
import moment from "moment";
import isDate from "lodash/isDate";

const moduleUser = {
  namespaced: true,
  state: {
    userInfo: null,
    branchInfo: null,
    branchPath: "",
    hasSubscribed: false,
    isFileStorageExpired: true,

    // linkedWithKtbPaotang: false,

    clinicList: [],
    doctorList: [],
    userList: [],
    userListIncludDeleted: [],

    paymentMethodList: [],
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data ? data : null;
    },
    setBranchInfo(state, data) {
      if (data && data.clinic) {
        state.branchInfo = data;
        state.branchPath = `${data.clinic.code}/${data.code}`;
        state.hasSubscribed = Boolean(state.branchInfo.fileStoreExpiration);

        const currentDate = new Date();
        const givenDate = new Date(state.branchInfo.fileStoreExpiration);
        givenDate < currentDate
          ? (state.isFileStorageExpired = true)
          : (state.isFileStorageExpired = false);

        // state.linkedWithKtbPaotang = data.linkedWithKtbPaotang;
      } else {
        state.branchInfo = null;
        state.branchPath = "";
        state.hasSubscribed = false;
        state.isFileStorageExpired = true;

        // state.linkedWithKtbPaotang = false;
      }
    },
    setAvatarId(state, id) {
      if (id && state.userInfo) {
        state.userInfo.contentId = id;
      }
    },
    setClinicList(state, data) {
      state.clinicList = data;
    },
    setDoctorList(state, data) {
      state.doctorList = data;
    },
    setUserList(state, data) {
      state.userList = data;
    },
    setUserListIncludDeleted(state, data) {
      state.userListIncludDeleted = data;
    },
    setPaymentMethodList(state, data) {
      state.paymentMethodList = data;
    },
  },
  actions: {
    //--------- API ---------//
    fetchClinicList({ commit }) {
      return (
        axios
          .get(`/api/user/clinic`)
          // .get("/api/user/clinic")
          .then(res => {
            commit("setClinicList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    updateProfile({ commit }, payload) {
      // อัพเดทโปรไฟล์
      return (
        axios
          .put(`/api/user/userInfo`, payload)
          // .put("/api/user/updateProfile", payload)
          .then(res => {
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    changePassword({ commit }, payload) {
      // แก้ไขรหัสผ่าน
      return (
        axios
          .put(`/api/user/changePassword`, payload)
          // .put("/api/user/changePassword", payload)
          .then(res => {
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    fetchUserInfo({ commit }) {
      return (
        axios
          .get(`/api/user/userInfo`)
          // .get("/api/user/userInfo")
          .then(res => {
            commit("setUserInfo", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    login({ commit }, payload) {
      return axios.post("/api/user/login", payload).then(res => {
        return res;
      });
    },
    logout({ commit }) {
      return axios
        .post(`/api/logout`)
        .then(res => {
          // return axios.post("/api/user/logout").then(res => {
          commit("setUserInfo", null);
          commit("setBranchInfo", null);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchUserLogin({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/user/checkLogin`)
          .then(res => {
            commit("setUserInfo", res.data.user);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    checkUserLogin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        var endPoint = `/user/checkLogin`;
        if (payload && payload.clinicUrl && payload.branchUrl) {
          endPoint = `/user/checkLogin/${payload.clinicUrl}/${payload.branchUrl}`;
        }
        axios
          .get(endPoint)
          .then(res => {
            commit("setUserInfo", res.data.user);
            commit("setBranchInfo", res.data.branch);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    newCheckUserLogin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const endPoint =
          payload && payload.clinicUrl && payload.branchUrl
            ? `/api/user/checkLogin/${payload.clinicUrl}/${payload.branchUrl}`
            : `/api/user/checkLogin`;

        axios
          .get(endPoint)
          .then(res => {
            commit("setUserInfo", res.data.user);
            commit("setBranchInfo", res.data.branch);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async registerUser({ commit }, payload) {
      try {
        return axios.post(`/api/user/register/`, payload).then(res => {
          return res;
        });
      } catch (err) {
        eventBus.$emit("apiError", err);
        throw err;
      }
    },
    checkUserActivate({ commit }, payload) {
      return axios.post(`/api/activate/`, payload).then(res => {
        return res;
      });
    },
    checkResetPasswordKey({ commit }, payload) {
      return axios
        .post(`/api/user/forgotPassword/verify`, payload)
        .then(res => {
          // return axios.post(`/api/user/getResetPassword/`, payload).then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    resetPassword({ commit }, payload) {
      return axios
        .post(`/api/user/resetPassword/`, payload)
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    forgotPassword({ commit }, payload) {
      return axios
        .post(`/api/user/forgotPassword/`, payload)
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchDoctorList({ commit }, payload) {
      return axios
        .get(`/api/user/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: { roleIds: "2" },
        })
        .then(res => {
          commit("setDoctorList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchUserList({ commit }, payload) {
      return axios
        .get(`/api/user/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: { roleIds: "1,2,3,4,5" },
        })
        .then(res => {
          commit("setUserList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchUserListIncludeDeleted({ commit }, payload) {
      return axios
        .get(`/api/user/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: { roleIds: "1,2,3,4,5", activeMembersOnly: false },
        })
        .then(res => {
          commit("setUserListIncludDeleted", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async fetchAllBranchMembers({ commit }, payload) {
      try {
        let res = await axios.get(
          `/api/user/${payload.clinicUrl}/${payload.branchUrl}`,
          { params: { roleIds: "1,2,3,4,5", activeMembersOnly: false } }
        );

        commit("setUserListIncludDeleted", res.data);

        let users = res.data.filter(i => i.deletedAt === null);
        commit("setUserList", users);

        let doctors = users.filter(i => i.authIdItems.includes(2));
        commit("setDoctorList", doctors);

        return res;
      } catch (err) {
        eventBus.$emit("apiError", err);
        throw err;
      }
    },

    fetchPaymentMethodList({ commit, getters }, payload) {
      return axios
        .get(
          `/api/master/mReceiptPayment/${payload.clinicUrl}/${payload.branchUrl}`
          // `/api/master/mReceiptPayment/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          let list = getters.getFormatPaymentMethodList(res.data.result);
          commit("setPaymentMethodList", list);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    verifyUser({ commit }, payload) {
      return axios
        .post(`/api/branch/verify/${payload.clinicUrl}/${payload.branchUrl}`, {
          username: payload.username,
          password: payload.password,
        })
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    verifyLicense({ commit }, payload) {
      return axios
        .post(
          `/api/user/tdc/verify/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            userId: payload.uid,
          }
        )
        .then(response => {
          // You might want to commit some mutation here
          // commit('SET_LICENSE_STATUS', response.data);
          return response.data; // Return the actual data
        })
        .catch(error => {
          eventBus.$emit("apiError", error);
          throw error;
        });
    },

    //--------- State ---------//
    setStateBranchInfo({ commit }, data) {
      commit("setBranchInfo", data);
    },
    setStateAvatarId({ commit }, id) {
      commit("setAvatarId", id);
    },
  },
  getters: {
    findBy: () => (dataList, value, key, defaultVal) => {
      if (!value) {
        return defaultVal;
      }
      return (
        dataList.find(function (item) {
          return item[key] == value;
        }) || defaultVal
      );
    },
    getUserInfo: state => {
      return state.userInfo;
    },
    getBranchInfo: state => {
      if (state.branchInfo) {
        state.branchInfo.confApptDuration = isDate(
          state.branchInfo.confApptDuration
        )
          ? moment(state.branchInfo.confApptDuration).format("HH:mm")
          : state.branchInfo.confApptDuration || "00:15"; //setting time period
        state.branchInfo.confOpeningTime = isDate(
          state.branchInfo.confOpeningTime
        )
          ? moment(state.branchInfo.confOpeningTime).format("HH:mm")
          : state.branchInfo.confOpeningTime || "09:00"; //setting  opening time
        state.branchInfo.confClosingTime = isDate(
          state.branchInfo.confClosingTime
        )
          ? moment(state.branchInfo.confClosingTime).format("HH:mm")
          : state.branchInfo.confClosingTime || "20:00"; //setting closing time
      }
      return state.branchInfo;
    },
    getClinicInfo: state => {
      if (state.branchInfo && state.branchInfo.clinic) {
        return state.branchInfo.clinic;
      } else {
        return {};
      }
    },
    getRoomList: state => {
      if (state.branchInfo && state.branchInfo.rooms) {
        return state.branchInfo.rooms.map(room => {
          room.title = room.name;
          return room;
        });
      } else {
        return [];
      }
    },
    getRoomListBy: (state, getters) => (value, key) => {
      return getters.findBy(state.branchInfo.rooms, value, key, {
        value: null,
        label: "-",
      });
    },
    getAuthorization: state => {
      if (state.branchInfo && state.branchInfo.auth) {
        return state.branchInfo.auth;
      } else {
        return { authId: 0 };
      }
    },
    getBranchPath: state => {
      return state.branchPath;
    },
    getClinicList: state => {
      return state.clinicList;
    },
    // getDoctorList: (state) => {
    //     return state.doctorList;
    // },
    getDoctorList: state => {
      let list = state.doctorList.sort((i, j) =>
        i.fullName.localeCompare(j.label)
      );
      return list;
    },
    getDoctorListBy: (state, getters) => (value, key) => {
      return getters.findBy(state.doctorList, value, key, {
        value: null,
        label: "-",
      });
    },
    getUserList: state => {
      let list = state.userList.sort((i, j) =>
        i.fullName.localeCompare(j.fullName)
      );
      return list;
    },
    getUserListBy: (state, getters) => (value, key) => {
      return getters.findBy(state.userList, value, key, {
        value: null,
        label: "-",
      });
    },
    getUserListIncludeDeletedBy: (state, getters) => (value, key) => {
      return getters.findBy(state.userListIncludDeleted, value, key, {
        value: null,
        label: "-",
      });
    },
    getPaymentMethodList: state => {
      return state.paymentMethodList;
    },
    getFormatPaymentMethodList: (state, getters) => data => {
      let list = [];
      data.forEach(i => {
        list.push(...i.mReceiptPayment);
      });
      return list;
    },

    isManager: state => {
      return state.branchInfo.auth.authIdItems.includes(1);
    },
    isDoctor: state => {
      return state.branchInfo.auth.authIdItems.includes(2);
    },
    isCounter: state => {
      return state.branchInfo.auth.authIdItems.includes(3);
    },
    isAssistant: state => {
      return state.branchInfo.auth.authIdItems.includes(4);
    },
    isHeadCounter: state => {
      return state.branchInfo.auth.authIdItems.includes(5);
    },
    getIsAccessMenu:
      state =>
      (roles = []) => {
        const roleList = {
          MANAGER: 1,
          DOCTOR: 2,
          COUNTER: 3,
          ASSISTANT: 4,
          HEADCOUNTER: 5,
        };
        roles = roles.map(role => roleList[String(role).toUpperCase()]);
        if (state.branchInfo && state.branchInfo.auth) {
          return state.branchInfo.auth.authIdItems.some(r => roles.includes(r));
        } else {
          return false;
        }
      },
    getLinkedWithKtbPaotang: state => {
      return state.branchInfo.linkedWithKtbPaotang;
    },
    getLinkedWithNhso: state => {
      return state.branchInfo.linkedWithNhso;
    },
    isFileStorageExpired: state => {
      return state.isFileStorageExpired;
    },
    hasSubscribed: state => {
      return state.hasSubscribed;
    },
  },
};
export default moduleUser;
