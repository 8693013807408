<template>
  <div class="hold-transition login-page bg-login view-user-login">
    <div class="login-box">
      <div class="card">
        <div class="card-body login-card-body">
          <div class="row">
            <div class="col-12 text-center mb-3">
              <img
                class="logo-login-img"
                src="@/assets/img/dentcloud-logo-darkblue.png"
              />
              <h3 class="logo-caption mt-2">
                <span class="">D</span>ent<span class="">C</span>loud
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="alert alert-warning alert-dismissible fade show"
                role="alert"
                v-if="statusLogin"
              >
                กรุณาตรวจสอบ อีเมลล์ หรือ รหัสผ่าน ให้ถูกต้อง
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="setStatusLogin"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <b-form @submit.prevent="submit" autocomplete="off">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="email">อีเมลล์</label>
                  <div class="input-group">
                    <input
                      id="login-input-login-view"
                      class="form-control form-control-sm"
                      v-model.trim="loginForm.username"
                      required
                      :disabled="isLoading"
                      autocomplete="username"
                      maxlength="100"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-user"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>รหัสผ่าน</label>
                  <div class="input-group">
                    <input
                      id="password-input-login-view"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control form-control-sm"
                      v-model="loginForm.password"
                      required
                      :disabled="isLoading"
                      autocomplete="current-password"
                      maxlength="100"
                    />
                    <div class="input-group-append">
                      <div
                        class="input-group-text cursor-pointer"
                        @click="togglePassword"
                      >
                        <span
                          :class="
                            showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                          "
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-row class="mb-2">
              <b-col cols="12">
                <b-form-checkbox
                  size="sm"
                  v-model="loginForm.rememberMe"
                  @change="saveToLocalStorage"
                  :disabled="isLoading"
                  >จดจำฉัน</b-form-checkbox
                >
              </b-col>
            </b-row>
            <div class="row">
              <div class="col-12">
                <button
                  type="submit"
                  class="btn btn-primary btn-block btn-sm"
                  :disabled="
                    isLoading || !loginForm.username || !loginForm.password
                  "
                >
                  {{ isLoading ? "กำลังเข้าสู่ระบบ..." : "เข้าสู่ระบบ" }}
                </button>
              </div>
            </div>
          </b-form>
          <div class="row">
            <div class="col-12">
              <span class="mb-0 mt-3 ml-auto">
                <a href="/user/forgotpassword" class="text-center"
                  >ลืมรหัสผ่าน?</a
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "Login",
  components: {
    Loading,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
      },
      statusLogin: false,
      showPassword: false,
    };
  },
  created() {
    this.fetchAppVersion();
    this.loadFromLocalStorage();
  },
  beforeDestroy() {
    // Clear sensitive data
    this.loginForm.password = "";
  },
  computed: {
    ...mapGetters({
      appVersion: "moduleMaster/getAppVersion",
      getLoginApiStatus: "moduleAuth/getLoginApiStatus",
    }),
  },
  methods: {
    ...mapActions({
      login: "moduleUser/login",
      fetchAppVersion: "moduleMaster/fetchAppVersion",
      actionLoginApi: "moduleAuth/loginApi",
    }),

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    async submit() {
      this.isLoading = true;
      try {
        const loginData = {
          username: this.loginForm.username.trim(),
          password: this.loginForm.password.trim(),
          rememberMe: this.loginForm.rememberMe,
        };

        const res = await this.actionLoginApi(loginData);

        if (res.status === 200) {
          // Clear sensitive data
          this.loginForm.password = "";

          // Redirect
          if (this.$route.query.from) {
            await this.$router.replace({
              path: this.$route.query.from,
            });
          } else {
            await this.$router.replace({ path: "/clinic" });
          }
        }
      } catch (err) {
        const statusCode = err.response?.data?.errorCode;

        if (statusCode === 401) {
          this.loginForm.password = "";
          this.statusLogin = true;
        } else {
          const errMessage =
            err.response?.data?.message || "ระบบขัดข้อง ขออภัยในความไม่สะดวก";
          this.showDialogToast("danger", errMessage);
        }
      } finally {
        this.isLoading = false;
      }
    },

    setStatusLogin() {
      this.statusLogin = false;
    },

    saveToLocalStorage(value) {
      localStorage.setItem("rememberMe", value);
    },

    loadFromLocalStorage() {
      this.loginForm.rememberMe = Boolean(
        JSON.parse(localStorage.getItem("rememberMe"))
      );
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>