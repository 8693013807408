<template>
  <div class="dentcloud-payment-overlay">
    <div class="overlay-content">
      <div class="animation-container">
        <div class="credit-card-container">
          <div class="credit-card">
            <div class="card-chip"></div>
            <div class="card-number">**** **** **** 1234</div>
            <svg
              class="card-logo"
              viewBox="0 0 160 160"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="logo-path"
                d="M107.17,97.94c.82-2.29,2.01-6.61,1.22-11.92-.36-2.41-.86-5.75-3.36-8.86-4.14-5.14-10.56-5.67-12.22-5.81-6.97-.58-12.05,2.85-13.52,3.92,2.26,8.72,2.47,10.67,2.21,10.75-.61,.19-2.04-10.37-9.47-14.06-6.04-2.99-12.52-.27-13.14,0-7.09,3.1-9.43,10.44-10.39,13.45-1.6,5.01-1.4,10.01-1.22,14.67,.06,1.5,.2,2.89,.47,5.67,.9,9.13,2,14.96,2.58,17.86,1.7,8.38,2.73,9.93,3.67,11,.71,.81,4.05,4.64,8.86,4.28,5.26-.4,8.08-5.48,8.25-5.81,.12-.23,.21-.44,.39-.86,1.71-3.98,1.8-6.48,2.61-10.03,.96-4.22,1.78-7.78,4.08-8.45,1.13-.33,2.2,.16,2.69,.4,3.5,1.68,3.15,6.15,6.11,13.6,2.09,5.26,3.58,9.33,7.16,10.53,1.79,.6,4.23,.54,6.13-.66,1.37-.87,2.19-2.16,2.96-4.05,1.64-4.07,1.74-7.76,2.39-16.66,.1-1.3,.4-4,2.33-6.29,.14-.16,.28-.3,.42-.43,2.38-2.21,5.68-2.14,7.95-2.14,3.38,0,13.82-.02,19.25-1.83,7.44-2.48,13.25-10.91,14.36-19.56,1.88-14.6-10.43-23.73-11-24.14-7.17-5.13-14.85-5.03-17.42-4.89-.39-3.94-1.72-11.85-7.33-19.56-8.37-11.49-20.43-14.55-24.75-15.59-3.75-.89-13.77-3.14-25.36,1.22-13.36,5.02-19.86,15.44-21.7,18.64-3.3,.28-10.74,1.36-18.03,6.72-2.06,1.51-10.65,8.18-13.45,20.47-.73,3.21-3.38,14.88,3.97,25.36,5.23,7.45,12.93,10.15,16.2,11.31,5.33,1.88,10.05,2.18,13.14,2.14"
              />
            </svg>
          </div>
          <div class="card-reader">
            <div class="reader-slot"></div>
          </div>
          <div class="card-swipe"></div>
        </div>
      </div>
      <p class="edc-instruction">กรุณาดำเนินการต่อที่เครื่อง EDC</p>
      <p class="countdown">เวลาที่เหลือ: {{ formattedCountdown }}</p>
      <div class="button-group">
        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="cancelTransaction"
        >
          ยกเลิก
        </b-button>
        <b-button
          variant="outline-warning"
          size="sm"
          @click="printReceipt"
          class="print-receipt-btn"
        >
          <span class="warning-icon">⚠️</span>
          พิมพ์ใบเสร็จ
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { ERROR_MESSAGE_USER_REQUEST_PRINT_RECEIPT } from "@/constants";

export default {
  name: "DentCloudPaymentOverlay",
  data() {
    return {
      countdown: 60000,
      countdownInterval: null,
    };
  },
  computed: {
    formattedCountdown() {
      const minutes = Math.floor(this.countdown / 60000);
      const seconds = ((this.countdown % 60000) / 1000).toFixed(0);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
  },
  methods: {
    cancelTransaction() {
      this.stopCountdown();
      eventBus.$emit("cancelTransaction");
      eventBus.$emit("edcProcessingStatus", false);
    },
    printReceipt() {
      const errorMessage = ERROR_MESSAGE_USER_REQUEST_PRINT_RECEIPT;
      ("Payment process terminated: User requested receipt printing");
      eventBus.$emit("transactionError", new Error(errorMessage));
      eventBus.$emit("edcProcessingStatus", false);
    },
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        this.countdown -= 1000;
        if (this.countdown <= 0) {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdownInterval);
    },
  },
  created() {
    this.startCountdown();
  },
  beforeDestroy() {
    this.stopCountdown();
    eventBus.$off("sendTransaction", this.startCountdown);
  },
};
</script>

<style scoped>
.dentcloud-payment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.overlay-content {
  position: relative;
  text-align: center;
  padding: 2rem;
  background-color: transparent;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1d5d8e;
}

.animation-container {
  width: 300px;
  height: 200px;
  margin: 0 auto 1rem;
  position: relative;
}

.credit-card-container {
  width: 100%;
  height: 100%;
  position: relative;
  perspective: 1000px;
}

.credit-card {
  width: 250px;
  height: 150px;
  background: linear-gradient(45deg, #1a237e, #3949ab);
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: cardInsert 3s infinite;
}

.card-chip {
  width: 40px;
  height: 30px;
  background-color: #ffd700;
  border-radius: 5px;
}

.card-number {
  color: white;
  font-size: 18px;
  letter-spacing: 2px;
}

.card-logo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
}

.logo-path {
  fill: none;
  stroke: white;
  stroke-width: 14px;
}

.card-reader {
  width: 270px;
  height: 60px;
  background-color: #333;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.reader-slot {
  width: 250px;
  height: 5px;
  background-color: #222;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes cardInsert {
  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(30px);
  }
}

.edc-instruction {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.countdown {
  font-size: 1.2rem;
  font-weight: bold;
  color: #e74c3c;
  margin-top: 1rem;
}

.button-group {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
</style>